import { useQueryClient } from '@tanstack/react-query';
import { ViewStateType } from 'components/ProductTemplates/types';
import { AppContext } from 'context/ContextProvider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppEventTypes } from 'types/AppType';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { useFetchCashBalanceUtillChange } from 'views/shared/hooks/useFetchCashBalanceUtillChange';
import { useNavigate } from 'react-router-dom';
import { Tabs } from '../../components';
import SlideOutPanel from '../../components/SlideOutPanel';
import { TabType } from '../../components/Tabs';
import { NavigationPath } from '../../types/DomainTypes';
import { buildDisplayText, capitalizeFirstLetter } from '../../utils';
import ProtectedPageLayout from '../shared/ProtectedPageLayout';
import { CurrentInvest } from './components/CurrentInvest';
import InvestSlideout from './components/InvestSlideout';
import { PreviousInvest } from './components/PreviousInvest';
import { storeCampaignPurchases } from './helpers';

import { InventTopUpEnum, InvestOffer, TabTypes, campaignPurchaseRefType } from './types';
import { StatusTypes, useHandleBuy } from 'views/shared/hooks/useHandleBuy';
import { IPurchaseParams } from 'views/shared/services/executeBuy';
import RequestWineOnFailedPurchase from 'views/shared/SlideOutViews/RequestWineOnFailedPurchase';

enum DisplayTextKeys {
  TITLE = 'invest:slideOutTitle',
  BUTTON_TEXT = 'buttonText',
}

enum SlideOutPanelViews {
  PRODUCT_DETAILS = 'product',
}

const Invest = () => {
  const TIME_OUT_DELAYS = {
    long: 5000,
    short: 1000,
    medium: 2000,
  };

  const queryClient = useQueryClient();

  const {
    dispatch,
    state: {
      settings: { accountInfo },
    },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const nav = useNavigate();
  const [viewState, setViewState] = useState<ViewStateType | null>();
  const [selectedTab, setSelectedTab] = useState(TabTypes.CURRENT);
  const { handlePurchase: executeBuy, mutateStatus: status, resetStatus } = useHandleBuy();
  const [buySlideOutState, setBuySlideOutState] = useState(false);
  const [purchaseRef, setPurchaseRef] = useState<campaignPurchaseRefType>();
  const { isBalanceChanged } = useFetchCashBalanceUtillChange(purchaseRef);

  const [slideOutPanelConfig, setSlideOutPanelConfig] = useState({
    open: false,
    offer: {} as InvestOffer,
    title: '',
    view: SlideOutPanelViews.PRODUCT_DETAILS,
    timestamp: Date.now(),
    displayView: ViewStateType.DEFAULT,
  });

  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'invest:wineDetails', t), [t]);

  const onItemSelect = (offer: InvestOffer, viewIn?: ViewStateType) => {
    setSlideOutPanelConfig({
      offer: { ...offer },
      open: true,
      title: displayText[DisplayTextKeys.TITLE],
      view: SlideOutPanelViews.PRODUCT_DETAILS,
      timestamp: Date.now(),
      displayView: viewIn ?? ViewStateType.DEFAULT,
    });
  };

  const onTabSelect = (tab: TabType) => {
    setSelectedTab(tab.value as TabTypes);
  };

  useEffect(() => {
    const hashValue = window.location.hash;

    const isSuccess = Boolean(hashValue && hashValue.startsWith(`#${InventTopUpEnum.onInvestSuccess}=`));
    const isFail = Boolean(hashValue && hashValue.startsWith(`#${InventTopUpEnum.onInvestFailure}=`));
    const isTargetHash = isSuccess || isFail;
    if (!isTargetHash) return;

    const purchaseInfoRef = atob(
      decodeURIComponent(
        hashValue.replace(
          isSuccess ? `#${InventTopUpEnum.onInvestSuccess}=` : `#${InventTopUpEnum.onInvestFailure}=`,
          '',
        ),
      ),
    );

    if (purchaseInfoRef) {
      const body = JSON.parse(purchaseInfoRef) as campaignPurchaseRefType;
      setPurchaseRef(body);
      window.history.replaceState({}, document.title, window.location.pathname);

      if (isSuccess) {
        setViewState(ViewStateType.TOP_UP_LOADING);
      }

      if (isFail) {
        setViewState(ViewStateType.LOADING);
        setTimeout(() => {
          setViewState(ViewStateType.ERROR_RESULT);
        }, 2000);
      }
      setBuySlideOutState(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash, setPurchaseRef]);

  const tabs = useMemo(
    () => [
      {
        id: TabTypes.CURRENT,
        value: TabTypes.CURRENT,
        title: <span className="text-14">{capitalizeFirstLetter(t(`invest:${TabTypes.CURRENT}`))}</span>,
        content: () => <CurrentInvest onItemSelect={onItemSelect} />,
      },
      // {
      //   id: TabTypes.PREVIOUS,
      //   value: TabTypes.PREVIOUS,
      //   title: <span className="text-14">{capitalizeFirstLetter(t(`invest:${TabTypes.PREVIOUS}`))}</span>,
      //   content: () => <PreviousInvest onItemSelect={onItemSelect} />,
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const onContinue = () => {
    const body = purchaseRef as campaignPurchaseRefType;

    const { lwin18, offerId, portfolioId, stringRef, priceGbp, quantity } = body;
    const params: IPurchaseParams = {
      headers: {
        email: '',
        operationType: 'CreateBuyOrder',
      },
      body: {
        portfolioId: portfolioId,
        description: stringRef ?? '',
        buys: [
          {
            lwin18: lwin18,
            offerId: offerId,
            price: priceGbp ?? 0,
            quantity: quantity,
          },
        ],
      },
    };
    setBuySlideOutState(true);
    setViewState(ViewStateType.LOADING);
    setTimeout(() => {
      executeBuy(params);
    }, TIME_OUT_DELAYS.short);
  };

  useEffect(() => {
    if (isBalanceChanged && status !== StatusTypes.success) {
      setViewState(ViewStateType.TOP_UP_SUCCESS);
      accountInfo?.account?.refetchCashBalances?.();
    }
    if (status === StatusTypes.isLoading) setViewState(ViewStateType.LOADING);
    if (status === StatusTypes.error) setViewState(ViewStateType.ERROR_RESULT);
    if (status === StatusTypes.success) accountInfo?.account?.refetchCashBalances?.();
  }, [status, dispatch, isBalanceChanged, accountInfo?.account]);

  useEffect(() => {
    if (status === StatusTypes.success) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          campaignPurchaseRef: {
            id: purchaseRef?.id || '',
            quantity: purchaseRef?.quantity || 0,
          },
        },
      });

      storeCampaignPurchases({
        campaignId: purchaseRef?.campaignId || 0,
        lwin18: purchaseRef?.lwin18 || '',
        quantity: purchaseRef?.quantity || 0,
        id: purchaseRef?.userId || '',
      });

      setViewState(ViewStateType.CAMPAIGN_PURCHASE);
    }
  }, [status, dispatch, purchaseRef]);

  const closeStatusModel = () => {
    setBuySlideOutState(false);
    setViewState(null);
  };

  const onClose = () => {
    setSlideOutPanelConfig({ ...slideOutPanelConfig, open: false });
    setTimeout(() => {
      accountInfo?.account?.refetchCashBalances?.();
      queryClient.refetchQueries({
        queryKey: ['fetchCampaignOffers'],
      });
    }, TIME_OUT_DELAYS.short);
  };

  const onClickViewPortfolio = () => {
    nav(`${NavigationPath.MY_PORTFOLIO}#find=${encodeURIComponent(btoa(JSON.stringify(purchaseRef)))}`);
  };

  return (
    <ProtectedPageLayout
      view={NavigationPath.INVEST}
      containerClassName="!overflow-hidden"
      title={t('common:invest')}
      onBack={() => null}
      showBackButton={false}
    >
      <>
        <Tabs items={tabs} onItemSelect={onTabSelect} value={selectedTab} />
        <SlideOutPanel
          headClassName="bg-vine"
          title={slideOutPanelConfig.title}
          isBackgroundDark={true}
          isOpen={slideOutPanelConfig.open}
          enableBgClose={false}
          onClose={onClose}
        >
          {slideOutPanelConfig.open && (
            <InvestSlideout
              requestTab={selectedTab}
              offer={slideOutPanelConfig.offer}
              timestamp={slideOutPanelConfig.timestamp}
              viewIn={slideOutPanelConfig.displayView}
              onClose={onClose}
            />
          )}
        </SlideOutPanel>
      </>

      {viewState && (
        <SlideOutPanel
          headClassName="bg-vine"
          title={slideOutPanelConfig.title}
          isBackgroundDark={true}
          isOpen={buySlideOutState}
          enableBgClose={false}
          onClose={() => {
            if (viewState === ViewStateType.LOADING) return;
            setBuySlideOutState(false);
          }}
        >
          <div className="flex flex-col flex-1 bg-gradient-to-b from-vine to-gray-500 pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[390px]">
            {viewState === ViewStateType.ERROR_RESULT && (
              <RequestWineOnFailedPurchase
                offer={purchaseRef as unknown as InvestOffer}
                onStatusChange={setViewState}
                qtyBought={purchaseRef?.quantity || 0}
                resetStatus={resetStatus}
                onClose={closeStatusModel}
              />
            )}
            {viewState !== ViewStateType.ERROR_RESULT && (
              <FeedbackTemplate
                viewState={viewState}
                onClose={closeStatusModel}
                onCTA={() => {
                  if (viewState === ViewStateType.CAMPAIGN_PURCHASE) {
                    onClickViewPortfolio();
                    return;
                  }
                  if (viewState === ViewStateType.TOP_UP_SUCCESS) {
                    onContinue();
                    return;
                  }
                  closeStatusModel();
                }}
              />
            )}
          </div>
        </SlideOutPanel>
      )}
    </ProtectedPageLayout>
  );
};

export default Invest;
