import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  GetMfaAuthenticatorsMutation,
  GetMfaAuthenticatorsMutationVariables,
  IssueMfaChallengeMutation,
  IssueMfaChallengeMutationVariables,
  LoginMutation,
  LoginMutationVariables,
} from '__generated__/graphql';
import { appIsWorkingVar } from '../../../graphql/cache';
import { GET_MFA_AUTHENTICATORS, ISSUE_MFA_CHALLENGE, LOGIN } from '../graphql/login';
import { executeLoginRequest } from '../helpers';
import { LoginModelProp, MfaRequestProps } from '../types';

type Model = {
  [key in LoginModelProp]: string;
};

export interface UseAuthPropTypes {
  onError: (e: Error) => void;
  errorHandler: (message: string) => void;
  onMFARequested?: (request: MfaRequestProps) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void;
}

export const useAuth = ({ errorHandler, onMFARequested, onError, onSuccess }: UseAuthPropTypes) => {
  const { t } = useTranslation();
  const [getMFAAuthenticators] = useMutation<GetMfaAuthenticatorsMutation, GetMfaAuthenticatorsMutationVariables>(
    GET_MFA_AUTHENTICATORS,
    {
      context: { serviceName: 'insecure' },
      // Catches network errors and returns them in errors in response
      onError: () => null,
    },
  );
  const [issueMFAChallenge] = useMutation<IssueMfaChallengeMutation, IssueMfaChallengeMutationVariables>(
    ISSUE_MFA_CHALLENGE,
    {
      context: { serviceName: 'insecure' },
      // Catches network errors and returns them in errors in response
      onError: () => null,
    },
  );

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN, {
    context: { serviceName: 'insecure' },
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });

  const onLogin = ({ email, password }: Model) => {
    executeLoginRequest({
      model: { email, password },
      appIsWorkingVar,
      login,
      errorHandler,
      t,
      getMFAAuthenticators,
      issueMFAChallenge,
      onError,
      onMFARequested,
      onSuccess,
    });
  };

  return { onLogin, getMFAAuthenticators, issueMFAChallenge, login };
};
