import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AccountOverviewIcon,
  Close,
  CultwineLogo,
  CultWineSidebarMenuLogo,
  HelpCenterIcon,
  PaymnetIcon,
  ProfileIcon,
  SettingsIcon,
} from '../../assets/icons';

import { Button } from '../../components';
import { NavigationPath } from '../../types/DomainTypes';
import { AccountViewType } from '../Accounts/types';
import { getSidebarIcons } from '../Portfolio/helpers';
import { SideBarItemType, SideBarProps } from './types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const termsAndConditions = process.env.REACT_APP_TERMS_AND_CONDITION_URL || '';
const version = process.env.REACT_APP_VERSION;

const SideBar: FC<SideBarProps> = ({ onClick, isSmallScreen, onClose, value }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [selectedView] = useState(AccountViewType.NONE);
  const { cardManagement14583 } = useFlags();
  const sidebarIcons = useMemo(() => getSidebarIcons(t, navigate), [t, navigate]);

  const navTo = useCallback(
    (accountViewType: AccountViewType) => {
      navigate(`${NavigationPath.ACCOUNTS}`, {
        state: { accountViewType },
      });
    },
    [navigate],
  );

  const mobileMenuOptions = useMemo(() => {
    const accountMenuOptions: SideBarItemType[] = [
      {
        title: t('common:overview'),
        icon: <AccountOverviewIcon fill="white" />,
        value: AccountViewType.OVERVIEW,
        onClick: () => {
          navTo(AccountViewType.OVERVIEW);
        },
      },
      {
        value: AccountViewType.PROFILE,
        title: t('common:profile'),
        icon: <ProfileIcon fill="white" />,
        onClick: () => {
          navTo(AccountViewType.PROFILE);
        },
      },
      {
        value: AccountViewType.PAYMENTS,
        title: t('common:payments'),
        icon: <PaymnetIcon fill="white" />,
        onClick: () => {
          navTo(AccountViewType.PAYMENTS);
        },
      },
      {
        value: AccountViewType.SETTINGS,
        title: t('common:settings'),
        icon: <SettingsIcon fill="white" />,
        onClick: () => {
          navTo(AccountViewType.SETTINGS);
        },
      },
      {
        value: AccountViewType.HELP_CENTER,
        title: t('common:help-center'),
        icon: <HelpCenterIcon fill="white" />,
        onClick: () => {
          navTo(AccountViewType.HELP_CENTER);
        },
      },
    ];

    const options = accountMenuOptions.filter((option) => {
      if (option.value === AccountViewType.PAYMENTS && !cardManagement14583) {
        return false;
      }
      return true;
    });
    return [...sidebarIcons, ...options];
  }, [cardManagement14583, sidebarIcons, navTo, t]);

  return (
    <>
      {!isSmallScreen && (
        <div className=" w-[100px] h-screen top-0 left-0 bg-vine hidden sm:flex flex-col pr-5 ">
          <div className="flex justify-center p-5">
            <CultwineLogo />
          </div>

          <div className="flex flex-col">
            {sidebarIcons.map((item, index) => {
              const title = item.title;
              const isSelected = item.value === value;
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (item.onClick) item.onClick();
                    else onClick(item);
                  }}
                  className={`flex flex-col cursor-pointer items-center mt-8 ${
                    isSelected ? 'border-orange border-l-[4px] pr-1' : ''
                  }`.trim()}
                >
                  {item.icon}
                  <span className="text-sm text-white mt-2">{title}</span>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col flex-1 px-3 pb-8 justify-end items-center text-white">
            <Button
              isLink={true}
              onClick={() => window.open(termsAndConditions, '_blank')}
              className="flex-wrap mb-10 text-xs text-center"
            >
              {t('portfolio:sideBarIcon.termsAndConditions')}
            </Button>

            <span className="flex-wrap mb-5 text-xs text-center">
              {t('portfolio:sideBarIcon.version', { version })}
            </span>
          </div>
        </div>
      )}

      {isSmallScreen && (
        <div className="sm:hidden absolute flex flex-col h-[100dvh] w-screen z-10 overflow-y-auto">
          <div className=" bg-vine">
            <div className="flex justify-center items-center py-10 relative">
              <div
                className="p-5 cursor-pointer absolute left-2"
                onClick={() => {
                  if (onClose) onClose();
                }}
              >
                <Close />
              </div>
              <CultWineSidebarMenuLogo className="flex-1" />
            </div>
            <div className="flex flex-col">
              {mobileMenuOptions.map((item, index) => {
                const title = item.title;
                return (
                  <div
                    key={`icon-${index}`}
                    onClick={() => {
                      if (item.onClick) item.onClick();
                      else onClick(item);
                      if (onClose) onClose();
                    }}
                    className="flex cursor-pointer border-t border-gray-200 items-center"
                  >
                    <div className="p-5"> {item.icon}</div>
                    <span className="text-sm flex-1 text-white ">{title}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex-1 bg-vine">
            {/* <AccSideMenu
              setSelectedView={(accountViewType: AccountViewType, subject?: SubjectOptionKeys) => {
                navigate(`${NavigationPath.ACCOUNTS}`, { state: { accountViewType, subject } });
                if (onClose) onClose();
              }}
              selectedView={selectedView}
              containerClassName="flex! w-full!"
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
