import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { updateAccessToken, updateRefreshToken, updateUserToken } from 'services/auth';
import { appIsWorkingVar, isLoggedInVar } from 'graphql/cache';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../components/AuthLayout';
import { getLoginValidationSchema } from '../helpers';
import { LoginModelProp, MfaRequestProps } from '../types';
import { useAuth, UseAuthPropTypes } from '../hooks/useAuthentication';
import { NavigationPath } from '../../../types/DomainTypes';
import { DisplayField, DisplayFieldType } from '../../../components/DisplayForms';
import { Button } from '../../../components';
import { classNames, validateEmail } from '../../../utils';
import { getFieldAttributes } from '../../../helpers';
import DisplayForm from '../../../components/DisplayForms/DisplayForm';
import { logError } from '../../../components/LogError';
import { ReactComponent as AppStoreLogo } from '../../../assets/icons/appStore.svg';
import { ReactComponent as PlayStoreLogo } from '../../../assets/icons/playStore.svg';

type Model = {
  [key in LoginModelProp]: string;
};
type FormValidationType = {
  [Key in LoginModelProp]: boolean;
};
const initialModel = {
  [`${LoginModelProp.EMAIL}`]: '',
  [`${LoginModelProp.PASSWORD}`]: '',
} as Model;

const validateLoginFormFunc = (value: string, modelKey: string) => {
  if (modelKey === LoginModelProp.EMAIL) {
    return validateEmail(value);
  }
  if (modelKey === LoginModelProp.PASSWORD) {
    return value.length >= 8;
  }
  return false;
};

interface LoginFormProps {
  emailAddress?: string;
  onMFARequested?: UseAuthPropTypes['onMFARequested'];
  onSucess?: () => void;
}

export const LoginForm: FC<LoginFormProps> = ({ onMFARequested, emailAddress, onSucess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isForceValid, setIsForceValid] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [formValidation, setFormValidation] = useState<FormValidationType>({
    [LoginModelProp.PASSWORD]: false,
    [LoginModelProp.EMAIL]: false,
  });

  const [model, setModel] = useState<Model>({
    ...initialModel,
  });

  const errorHandler = (message: string) => {
    throw new Error(message);
  };

  const { onLogin } = useAuth({
    errorHandler,
    onError: (e: Error) => {
      setIsLoggingIn(false);
      setError(e);
      logError(e);
    },
    onMFARequested: (request: MfaRequestProps) => {
      setIsLoggingIn(false);
      if (onMFARequested) onMFARequested(request);
    },
    onSuccess: ({ portalAuthLogin: { accessToken, refreshToken, userToken } }) => {
      setIsLoggingIn(false);
      updateAccessToken(accessToken);
      updateRefreshToken(refreshToken);
      updateUserToken(userToken);
      onSucess?.();
      navigate(NavigationPath.PORTFOLIO);
      isLoggedInVar(true);
      appIsWorkingVar(false);
    },
  });
  const onClear = (key: string) => {
    if (isForceValid) setIsForceValid(false);
    formik.setFieldValue(key, '');
    setFormValidation({ ...formValidation, [key]: false });
  };

  const updateFormValidation = (value: string, modelKey: LoginModelProp) => {
    const validationResult = validateLoginFormFunc(value, modelKey);
    if (formValidation[modelKey] !== validationResult) {
      setFormValidation({ ...formValidation, [modelKey]: validationResult });
    }
  };
  const onForgetPassword = () => {
    navigate(NavigationPath.FORGET_PASSWORD);
  };

  const onRegister = () => {
    navigate(NavigationPath.REGISTRATION);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field?: DisplayField) => {
    e.preventDefault();
    formik.handleChange(e);
    if (isForceValid) setIsForceValid(false);
    const { value } = e.target;
    updateFormValidation(value, field!.modelKey as LoginModelProp);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field?: DisplayField) => {
    formik.handleBlur(e);
    if (isForceValid) setIsForceValid(false);
    const { value } = e.target;
    updateFormValidation(value, field!.modelKey as LoginModelProp);
  };

  const mValidationSchema = yup.object({
    ...getLoginValidationSchema(t),
  });

  const formik = useFormik<Model>({
    initialValues: { ...initialModel, email: emailAddress || '' },
    onSubmit: async (values) => {
      setIsLoggingIn(true);
      onLogin(values);
    },
    validationSchema: mValidationSchema || model,
  });
  const onFieldUpdate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field?: DisplayField) => {
    handleChange(e, field);
    setModel({ ...model, [`${field!.modelKey}`]: e.target.value });
  };

  const loginForm = useMemo(() => {
    const emailHasError = formik.touched[LoginModelProp.EMAIL] && formik.errors[LoginModelProp.EMAIL];
    const emailAttrs = getFieldAttributes(LoginModelProp.EMAIL, LoginModelProp.EMAIL, t('auth:loginForm.email.title'));
    const emailField = {
      ...emailAttrs,
      disabled: isLoggingIn,
      inputProps: {
        showClearButton: true,
        inputClassName: 'bg-transparent',
        inputContainerClassName: emailHasError ? '!border-red' : '!border-gray-300',
      },
      onClear: (dField?: DisplayField) => {
        if (dField) onClear(dField.id);
      },
      onChange: onFieldUpdate,
      onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, field?: DisplayField) => {
        handleBlur(e, field);
      },

      helperText: emailHasError ? formik.errors[LoginModelProp.EMAIL] : '',
      label: {
        ...emailAttrs.label,
        className: classNames(`${emailAttrs.label}`, `${emailHasError ? 'text-red' : ''}`),
      },
    };
    const pwdAttrs = getFieldAttributes(
      LoginModelProp.PASSWORD,
      LoginModelProp.PASSWORD,
      t('auth:loginForm.password.title'),
    );
    const pwdHasError = formik.touched[LoginModelProp.PASSWORD] && formik.errors[LoginModelProp.PASSWORD];
    const passwordField = {
      ...pwdAttrs,
      disabled: isLoggingIn,
      className: 'border-gray-500',
      type: DisplayFieldType.PASSWORD,
      inputProps: {
        showClearButton: true,
        inputClassName: 'bg-transparent',
        inputContainerClassName: '!border-gray-300',
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key.toLowerCase() === 'enter') {
            formik.handleSubmit();
          }
        },
      },
      helperText: pwdHasError ? formik.errors[LoginModelProp.PASSWORD] : '',
      onChange: onFieldUpdate,
      onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, fieldIn?: DisplayField) => {
        handleBlur(e, fieldIn);
      },
      onClear: (dField?: DisplayField) => {
        if (dField) onClear(dField.id);
      },
      label: {
        ...pwdAttrs.label,
        className: classNames(`${pwdAttrs.label}`, `${pwdHasError ? 'text-red' : ''}`),
      },
    };

    const isFormValid =
      isForceValid || (formValidation[LoginModelProp.PASSWORD] && formValidation[LoginModelProp.EMAIL]);
    return {
      isValid: isFormValid,
      sections: [
        {
          className: 'w-full  gap-5',
          fields: [emailField, passwordField],
        },
      ],
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, formik]);

  const formIsValid = isForceValid || (formValidation[LoginModelProp.PASSWORD] && formValidation[LoginModelProp.EMAIL]);

  useEffect(() => {
    const validations = Object.keys(initialModel).reduce((result: boolean[], key) => {
      const value = formik.values[key as keyof Model];
      updateFormValidation(value, key as LoginModelProp);
      return [...result, validateLoginFormFunc(value, key)];
    }, []);
    if (!validations.includes(false)) setIsForceValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout
      title={t('auth:loginForm.title')}
      error={{ body: error?.message || '', onClose: () => setError(null) }}
      subTitle={t('auth:loginForm.subTitle')}
    >
      <div className="w-[70%] max-w-[420px]">
        <DisplayForm
          titleContainerClassName="mt-10"
          sectionsContainerClassName="pb-1"
          sections={loginForm.sections}
          title=""
          model={{ ...model, modelType: 'login' }}
        />
        <div className="mt-4">
          <Button isLink={true} onClick={onForgetPassword} className="btn-link">
            {t('auth:loginForm.forgotPasswordLink')}
          </Button>
        </div>

        <Button
          onClick={formik.handleSubmit}
          type="submit"
          isDisable={!formIsValid}
          className={`w-full mt-10 mb-3 ${!formIsValid ? 'btn-disabled' : 'btn-primary'}`}
          isProcessing={isLoggingIn}
          props={{
            name: 'login',
          }}
        >
          {t('auth:loginForm.login')}
        </Button>
        <div className="w-full text-sm  mt-2 flex justify-center">
          <span>
            {t('auth:loginForm.registerInfo')}
            <button className="hover: underline ml-1" onClick={onRegister}>{`'${t(
              'auth:loginForm.registerLink',
            )}'`}</button>
          </span>
        </div>

        <div className="mt-10 flex-col items-center flex">
          <span className="text-center">{t('auth:downloadApp')}</span>
          <div className="mt-4 flex flex-row">
            <a
              href="https://apps.apple.com/app/cult-wines-investment-portal/id1660208790"
              target="_blank"
              rel="noreferrer"
            >
              <AppStoreLogo className="mr-4" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.portalmobile.cw"
              target="_blank"
              rel="noreferrer"
            >
              <PlayStoreLogo />
            </a>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
